.c-modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  position: absolute;
  border-radius: 5px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
}

.c-modal__head {
  padding: 0.5rem;
  background-color: rgb(62, 144, 199);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: large;
  border-radius: 5px 5px 0px 0px;
}

.c-modal__body {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: auto;
  scroll-behavior: smooth;
  flex: 1;
}

.c-modal__footer {
  padding: 1rem;
  overflow: hidden;
}

.c-modal__loader {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  display: flex;
  background-color: rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}